import React, { Component } from "react";

import ReactHtmlParser from "react-html-parser"
import { AnchorLink } from "gatsby-plugin-anchor-links";




class Figure extends Component {



    render() {


        return (
            <div className="container-fluid home-infraestructura">
                <div className="grid">

                    {
                        this.props.figure.map((item) => {
                            return (
                                <figure key={item.id} className="effect-duke">
                                    <img src={item.fondo[0].url} alt={item.fondo[0].url} />
                                    <figcaption>
                                        <h2>
                                            <img src={item.icono[0].url} alt={item.icono[0].url} className="icons" /><br />
                                            {ReactHtmlParser(item.titulo)}</h2>
                                        <br />
                                        <p>{item.descripcion}<br />
                                            {item.url &&

                                                <AnchorLink to={item.url}>{item.btn ? item.btn : this.props.text}</AnchorLink >

                                            }</p>
                                        <p></p>
                                    </figcaption>
                                </figure>
                            )
                        })
                    }
                </div>
            </div>




        )
    }
}

export default Figure
