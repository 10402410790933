import React, { Component } from "react";


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


class Certificado extends Component {



    render() {


        return (
            <section className="padding-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 titulo-center text-center margin-b-40">
                            <h4>
                                <img src={this.props.certificado.logo.url} alt={this.props.certificado.logo.url} />
                                
                                {this.props.certificado.titulo}                  </h4>
                        </div>
                        <Container>
                            <Row className="justify-content-md-center">


                                {
                                    this.props.certificado.single_img.map((item, index) => {
                                        return (

                                            <div  key={index} className="col-6 col-md-3 col-lg-2  text-center wow fadeInUp"  >
                                                <p>
                                                    <img src={item.img.url} alt={item.img.url} />
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>




        )
    }
}

export default Certificado
